<template>
  <div class="container">
    <el-dialog title="失败日志" width="1000px" v-model="dialogVisible">
      <div class="wrap">
        <div class="wrap-header">
          <div v-if="fromKey==0">群发名称:<span>{{ groupObj.name }}</span></div>
          <div v-if="fromKey==1">单发名称:<span>{{ groupObj.name }}</span></div>

          <el-button v-if="disableLotsSend&&typeKey!=2" >批量重发</el-button>
          <el-button type="primary" @click="confirmAction" v-if="!disableLotsSend&&typeKey!=2">批量重发</el-button>
        </div>

        <el-table :data="tableData" style="width: 100%" border max-height="500">
          <el-table-column prop="ChatRoomName" label="群名称" v-if="fromKey==0"></el-table-column>
          <el-table-column prop="FriendName" label="客户名称" v-if="fromKey==1"></el-table-column>
          <el-table-column prop="RobotName" label="助手"></el-table-column>
          <el-table-column
            prop="SendDateTime"
            label="发送时间"
          ></el-table-column>
          <el-table-column label="内容一" width="120">
            <template #default="scope">
              <div
                class="cell-wrap"
                v-if="scope.row.MaterialPack && scope.row.MaterialPack[0]"
              >
                <p class="cellleft">
                  {{ scope.row.MaterialPack[0].MsgTypeStr }}
                </p>

                <el-popover
                  v-if="scope.row.MaterialPack[0].StatusStr == '发送失败'"
                  title="失败原因"
                  :width="200"
                  trigger="hover"
                  :content="scope.row.MaterialPack[0].CallBackvcResult"
                >
                  <template #reference>
                    <p class="cellright redColor">
                      {{ scope.row.MaterialPack[0].StatusStr }}
                    </p>
                  </template>
                </el-popover>

                <p
                  v-else
                  class="cellright"
                  :class="{
                    grayColor: scope.row.MaterialPack[0].StatusStr == '重发中',
                  }"
                >
                  {{ scope.row.MaterialPack[0].StatusStr }}
                </p>
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column label="内容二" width="120">
            <template #default="scope">
              <div
                class="cell-wrap"
                v-if="scope.row.MaterialPack && scope.row.MaterialPack[1]"
              >
                <p class="cellleft">
                  {{ scope.row.MaterialPack[1].MsgTypeStr }}
                </p>
                <el-popover
                  v-if="scope.row.MaterialPack[1].StatusStr == '发送失败'"
                  title="失败原因"
                  :width="200"
                  trigger="hover"
                  :content="scope.row.MaterialPack[1].CallBackvcResult"
                >
                  <template #reference>
                    <p class="cellright redColor">
                      {{ scope.row.MaterialPack[1].StatusStr }}
                    </p>
                  </template>
                </el-popover>

                <p
                  v-else
                  class="cellright"
                  :class="{
                    grayColor: scope.row.MaterialPack[1].StatusStr == '重发中',
                  }"
                >
                  {{ scope.row.MaterialPack[1].StatusStr }}
                </p>
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column label="内容三" width="120">
            <template #default="scope">
              <div
                class="cell-wrap"
                v-if="scope.row.MaterialPack && scope.row.MaterialPack[2]"
              >
                <p class="cellleft">
                  {{ scope.row.MaterialPack[2].MsgTypeStr }}
                </p>
                <el-popover
                  v-if="scope.row.MaterialPack[2].StatusStr == '发送失败'"
                  title="失败原因"
                  :width="200"
                  trigger="hover"
                  :content="scope.row.MaterialPack[2].CallBackvcResult"
                >
                  <template #reference>
                    <p class="cellright redColor">
                      {{ scope.row.MaterialPack[2].StatusStr }}
                    </p>
                  </template>
                </el-popover>

                <p
                  v-else
                  class="cellright"
                  :class="{
                    grayColor: scope.row.MaterialPack[2].StatusStr == '重发中',
                  }"
                >
                  {{ scope.row.MaterialPack[2].StatusStr }}
                </p>
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column label="内容四" width="120">
            <template #default="scope">
              <div
                class="cell-wrap"
                v-if="scope.row.MaterialPack && scope.row.MaterialPack[3]"
              >
                <p class="cellleft">
                  {{ scope.row.MaterialPack[3].MsgTypeStr }}
                </p>
                <el-popover
                  v-if="scope.row.MaterialPack[3].StatusStr == '发送失败'"
                  title="失败原因"
                  :width="200"
                  trigger="hover"
                  :content="scope.row.MaterialPack[3].CallBackvcResult"
                >
                  <template #reference>
                    <p class="cellright redColor">
                      {{ scope.row.MaterialPack[3].StatusStr }}
                    </p>
                  </template>
                </el-popover>

                <p
                  v-else
                  class="cellright"
                  :class="{
                    grayColor: scope.row.MaterialPack[3].StatusStr == '重发中',
                  }"
                >
                  {{ scope.row.MaterialPack[3].StatusStr }}
                </p>
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100" align="center" v-if="typeKey!=2">
            <template #default="scope">
              <el-button v-if="scope.row.RowRetry == 0">发送中</el-button>
              <el-button type="primary" @click="resendAction(scope.row)" v-if="scope.row.RowRetry == 1">重发</el-button>
            </template>
          </el-table-column>
        </el-table>
        <TableFoot
        :pagination="pageObj"
        @changePage="changePage"
        @changeSize="changeSize"
      />
      </div>

      <template #footer>
        <div class="dialog-footer">
          <!-- <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmAction">确 定</el-button> -->
          <!-- <el-button type="primary" @click="confirmAction">批量处理</el-button> -->
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import {
  GetFailureList,
  GroupSendRetry,
  GetPrivateFailureList,
  PrivateSendRetry,
} from "@/helper/group";
import TableFoot from "@/components/TableFoot";
import tools from "@/assets/js/tools";
export default {
  components: {
    TableFoot,
  },
  setup(props, context) {
    const dialogVisible = ref(false);
    const tableData = ref([]);
    const disableLotsSend = ref(false)
    const groupObj = reactive({
      name: "",
      id: "",
      groupId: [],
      privateId: [],
    });
    const fromKey = ref(0); //0:群聊  1 私聊
    const typeKey = ref(1); //1:API推送 2:群矩阵推送

    function initCop(item, from = 0,type) {
      console.log(item);
      dialogVisible.value = true;
      groupObj.name = item.Name;
      groupObj.id = item.ID;
      fromKey.value = from;
      typeKey.value= type||1
      search();
    }

    function search() {
      if (fromKey.value == 0) {
        let params = {
             GroupSendID: groupObj.id,
             PageNum:pageObj.PageIndex,
             PageSize:pageObj.PageSize,
             type:typeKey.value
        }
        GetFailureList(params).then((res) => {
           pageObj.TotalRow = res.TotalCount
          groupObj.groupId = res.GroupSendID;
          disableLotsSend.value = res.TotalRetry==0?true:false;
          res.List.forEach((result) => {
            result.MaterialPack.forEach((result2) => {
              result2.MsgTypeStr = tools.changeMaterialtypeToStr(
                result2.MsgType
              );
              result2.StatusStr = tools.changeMsgtypeToStr(result2.Status);
            });
          });
          tableData.value = res.List;
        });
      } else {
        let params = {
             PrivateSendID: groupObj.id,
             PageNum:pageObj.PageIndex,
             PageSize:pageObj.PageSize
        }
        GetPrivateFailureList(params).then((res) => {
          pageObj.TotalRow = res.TotalCount
          groupObj.privateId = res.PrivateSendID;
          disableLotsSend.value = res.TotalRetry==0?true:false;
          res.List.forEach((result) => {
            result.MaterialPack.forEach((result2) => {
              result2.MsgTypeStr = tools.changeMaterialtypeToStr(
                result2.MsgType
              );
              result2.StatusStr = tools.changeMsgtypeToStr(result2.Status);
            });
          });
          tableData.value = res.List;
        });
      }
    }
    /*************点击事件****************/
    //重发
    function resendAction(item) {
      
      if (fromKey.value == 0) {
        let params = {
          GroupSendID: groupObj.groupId,
          GroupID: item.GroupID,
        };
        GroupSendRetry(params).then((res) => {
          search();
        });
      } else {
        let params = {
          PrivateSendID: groupObj.privateId,
          FriendSerialNo: item.FriendSerialNo,
        };
        PrivateSendRetry(params).then((res) => {
          search();
        });
      }
    }

    //确认
    function confirmAction() {
      if (fromKey.value == 0) {
        let params = {
          GroupSendID: groupObj.groupId,
          GroupID: 0,
        };
        GroupSendRetry(params).then((res) => {
          search();
          context.emit("onConfirm");
        });
      } else {
        let params = {
          PrivateSendID: groupObj.privateId,
          FriendSerialNo: "",
        };
        PrivateSendRetry(params).then((res) => {
          search();
          context.emit("onConfirm");
        });
      }
    }

    /****************TableFoot*******************/
    const pageObj = reactive({
      TotalRow: 0,
      PageIndex: 1,
      PageSize: 10,
    });
    //页码改变
    function changePage(index) {
      pageObj.PageIndex = index;
      search();
    }
    function changeSize(size) {
      pageObj.PageSize = size;
      pageObj.PageIndex = 1;
      search();
    }

    return {
      dialogVisible,
      initCop,
      confirmAction,
      tableData,
      resendAction,
      groupObj,
      fromKey,
      disableLotsSend,
      changePage,
      changeSize,
      pageObj,
      typeKey
    };
  },
};
</script>
<style lang='scss' scoped>
.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;

  .wrap-header {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: $color-text;
  }
}

.cell-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 10px;

  .cellleft {
    color: $color-blue;
    padding: 0 5px;
    margin-right: 5px;
    border-right: 1px solid #999;
  }

  .cellright {
    color: $color-common;
  }

  .grayColor {
    color: #ccc;
  }

  .redColor {
    color: red;
  }
}

:deep().el-button--default {
  background: #ccc !important;
  border-color: #ccc !important;
  color: #ffffff !important;
  cursor: auto;
}
</style>